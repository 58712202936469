import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class Filter extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  willDestroy() {
    super.willDestroy(...arguments);
    this.sidebarState.clearFilter();
  }
  get shouldDisplay() {
    return this.sidebarState.currentPanel.filterable;
  }
  get displayClearFilter() {
    return this.sidebarState.filter.length > 0;
  }
  setFilter(event1) {
    this.sidebarState.filter = event1.target.value.toLowerCase();
  }
  static #_2 = (() => dt7948.n(this.prototype, "setFilter", [action]))();
  handleEscape(event1) {
    if (event1.key === "Escape") {
      event1.stopPropagation();
      if (this.sidebarState.filter.length > 0) {
        this.sidebarState.filter = "";
      } else {
        event1.target.blur();
      }
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "handleEscape", [action]))();
  clearFilter() {
    this.sidebarState.clearFilter();
    document.querySelector(".sidebar-filter__input").focus();
  }
  static #_4 = (() => dt7948.n(this.prototype, "clearFilter", [action]))();
  static #_5 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#if this.shouldDisplay}}
        <div class="sidebar-filter">
          <input
            {{on "input" this.setFilter}}
            {{on "keydown" this.handleEscape}}
            value={{this.sidebarState.filter}}
            placeholder={{i18n "sidebar.filter"}}
            type="text"
            class="sidebar-filter__input"
          />
  
          {{#if this.displayClearFilter}}
            <DButton
              @action={{this.clearFilter}}
              @icon="times"
              class="sidebar-filter__clear"
            />
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "3OvNurL8",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-filter\"],[12],[1,\"\\n        \"],[11,\"input\"],[16,2,[30,0,[\"sidebarState\",\"filter\"]]],[16,\"placeholder\",[28,[32,0],[\"sidebar.filter\"],null]],[24,0,\"sidebar-filter__input\"],[24,4,\"text\"],[4,[32,1],[\"input\",[30,0,[\"setFilter\"]]],null],[4,[32,1],[\"keydown\",[30,0,[\"handleEscape\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayClearFilter\"]],[[[1,\"          \"],[8,[32,2],[[24,0,\"sidebar-filter__clear\"]],[[\"@action\",\"@icon\"],[[30,0,[\"clearFilter\"]],\"times\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/filter.js",
    "scope": () => [i18n, on, DButton],
    "isStrictMode": true
  }), this))();
}