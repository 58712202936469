import { registerDestructor } from "@ember/destroyable";
import { inject as service } from "@ember/service";
import Modifier from "ember-modifier";
export default class DBreadcrumbsContainerModifier extends Modifier {
  static #_ = (() => dt7948.g(this.prototype, "breadcrumbsService", [service]))();
  #breadcrumbsService = (() => (dt7948.i(this, "breadcrumbsService"), void 0))();
  container = null;
  modify(element, _, _ref) {
    let {
      itemClass,
      linkClass
    } = _ref;
    if (this.container) {
      return;
    }
    this.container = {
      element,
      itemClass,
      linkClass
    };
    this.breadcrumbsService.registerContainer(this.container);
    registerDestructor(this, unregisterContainer);
  }
}
function unregisterContainer(instance) {
  if (instance.container) {
    instance.breadcrumbsService.unregisterContainer(instance.container);
  }
}