import Component from "@glimmer/component";
import { service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class RenderGlimmerContainer extends Component {
  static #_ = (() => dt7948.g(this.prototype, "renderGlimmer", [service]))();
  #renderGlimmer = (() => (dt7948.i(this, "renderGlimmer"), void 0))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#each this.renderGlimmer._registrations as |info|}}
        {{#in-element info.element insertBefore=null}}
          <info.component
            @data={{info.data}}
            @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
          />
        {{/in-element}}
      {{/each}}
    
  */
  {
    "id": "NknMf/qr",
    "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"renderGlimmer\",\"_registrations\"]]],null]],null],null,[[[40,[[[1,\"        \"],[8,[30,1,[\"component\"]],null,[[\"@data\",\"@setWrapperElementAttrs\"],[[30,1,[\"data\"]],[30,1,[\"setWrapperElementAttrs\"]]]],null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[31,3],[[30,1,[\"element\"]]],null],null]],[1]],null],[1,\"  \"]],[\"info\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/render-glimmer-container.js",
    "isStrictMode": true
  }), this))();
}