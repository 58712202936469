import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import DropdownMenu from "discourse/components/dropdown-menu";
import BulkTopicActions, { addBulkDropdownAction } from "discourse/components/modal/bulk-topic-actions";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import DMenu from "float-kit/components/d-menu";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
const _customButtons = [];
const _customOnSelection = {};
export function addBulkDropdownButton(opts1) {
  _customButtons.push({
    id: opts1.id,
    icon: opts1.icon,
    name: i18n(opts1.label),
    visible: opts1.visible,
    class: opts1.class
  });
  addBulkDropdownAction(opts1.id, opts1.action);
  const actionOpts1 = {
    label: opts1.label,
    setComponent: true
  };
  if (opts1.actionType === "performAndRefresh") {
    actionOpts1.setComponent = false;
  }
  _customOnSelection[opts1.id] = actionOpts1;
}
export default class BulkSelectTopicsDropdown extends Component {
  static #_ = (() => dt7948.g(this.prototype, "modal", [service]))();
  #modal = (() => (dt7948.i(this, "modal"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [service]))();
  #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  get buttons() {
    let options1 = [{
      id: "update-category",
      icon: "pencil-alt",
      name: i18n("topic_bulk_actions.update_category.name")
    }, {
      id: "update-notifications",
      icon: "d-regular",
      name: i18n("topic_bulk_actions.update_notifications.name")
    }, {
      id: "reset-bump-dates",
      icon: "anchor",
      name: i18n("topic_bulk_actions.reset_bump_dates.name")
    }, {
      id: "defer",
      icon: "circle",
      name: i18n("topic_bulk_actions.defer.name"),
      visible: _ref => {
        let {
          currentUser: currentUser1
        } = _ref;
        return currentUser1.user_option.enable_defer;
      }
    }, {
      id: "close-topics",
      icon: "lock",
      name: i18n("topic_bulk_actions.close_topics.name")
    }, {
      id: "archive-topics",
      icon: "folder",
      name: i18n("topic_bulk_actions.archive_topics.name")
    }, {
      id: "unlist-topics",
      icon: "far-eye-slash",
      name: i18n("topic_bulk_actions.unlist_topics.name"),
      visible: _ref2 => {
        let {
          topics: topics1
        } = _ref2;
        return topics1.some(t1 => t1.visible) && !topics1.some(t1 => t1.isPrivateMessage);
      }
    }, {
      id: "relist-topics",
      icon: "far-eye",
      name: i18n("topic_bulk_actions.relist_topics.name"),
      visible: _ref3 => {
        let {
          topics: topics1
        } = _ref3;
        return topics1.some(t1 => !t1.visible) && !topics1.some(t1 => t1.isPrivateMessage);
      }
    }, {
      id: "append-tags",
      icon: "tag",
      name: i18n("topic_bulk_actions.append_tags.name"),
      visible: _ref4 => {
        let {
          currentUser: currentUser1,
          siteSettings: siteSettings1
        } = _ref4;
        return siteSettings1.tagging_enabled && currentUser1.canManageTopic;
      }
    }, {
      id: "replace-tags",
      icon: "tag",
      name: i18n("topic_bulk_actions.replace_tags.name"),
      visible: _ref5 => {
        let {
          currentUser: currentUser1,
          siteSettings: siteSettings1
        } = _ref5;
        return siteSettings1.tagging_enabled && currentUser1.canManageTopic;
      }
    }, {
      id: "remove-tags",
      icon: "tag",
      name: i18n("topic_bulk_actions.remove_tags.name"),
      visible: _ref6 => {
        let {
          currentUser: currentUser1,
          siteSettings: siteSettings1
        } = _ref6;
        return siteSettings1.tagging_enabled && currentUser1.canManageTopic;
      }
    }, {
      id: "delete-topics",
      icon: "trash-alt",
      name: i18n("topic_bulk_actions.delete_topics.name"),
      visible: _ref7 => {
        let {
          currentUser: currentUser1
        } = _ref7;
        return currentUser1.staff;
      }
    }];
    return [...options1, ..._customButtons].filter(_ref8 => {
      let {
        visible: visible1
      } = _ref8;
      if (visible1) {
        return visible1({
          topics: this.args.bulkSelectHelper.selected,
          currentUser: this.currentUser,
          siteSettings: this.siteSettings
        });
      } else {
        return true;
      }
    });
  }
  showBulkTopicActionsModal(actionName1, title1) {
    let opts1 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let allowSilent1 = false;
    let initialAction1 = null;
    let initialActionLabel1 = null;
    let description1 = null;
    if (opts1.allowSilent === true) {
      allowSilent1 = true;
    }
    if (opts1.custom === true) {
      title1 = i18n(_customOnSelection[actionName1].label);
      initialActionLabel1 = actionName1;
      if (opts1.setComponent === true) {
        initialAction1 = "set-component";
      }
    } else {
      title1 = i18n(`topics.bulk.${title1}`);
    }
    if (opts1.description) {
      description1 = opts1.description;
    }
    this.modal.show(BulkTopicActions, {
      model: {
        action: actionName1,
        title: title1,
        description: description1,
        bulkSelectHelper: this.args.bulkSelectHelper,
        refreshClosure: () => this.router.refresh(),
        allowSilent: allowSilent1,
        initialAction: initialAction1,
        initialActionLabel: initialActionLabel1
      }
    });
  }
  onSelect(id1) {
    switch (id1) {
      case "update-category":
        this.showBulkTopicActionsModal(id1, "change_category", {
          description: i18n(`topic_bulk_actions.update_category.description`)
        });
        break;
      case "update-notifications":
        this.showBulkTopicActionsModal(id1, "notification_level", {
          description: i18n(`topic_bulk_actions.update_notifications.description`)
        });
        break;
      case "close-topics":
        this.showBulkTopicActionsModal("close", "close_topics", {
          allowSilent: true
        });
        break;
      case "archive-topics":
        this.showBulkTopicActionsModal("archive", "archive_topics");
        break;
      case "unlist-topics":
        this.showBulkTopicActionsModal("unlist", "unlist_topics");
        break;
      case "relist-topics":
        this.showBulkTopicActionsModal("relist", "relist_topics");
        break;
      case "append-tags":
        this.showBulkTopicActionsModal(id1, "choose_append_tags");
        break;
      case "replace-tags":
        this.showBulkTopicActionsModal(id1, "change_tags");
        break;
      case "remove-tags":
        this.showBulkTopicActionsModal(id1, "remove_tags");
        break;
      case "delete-topics":
        this.showBulkTopicActionsModal("delete", "delete");
        break;
      case "reset-bump-dates":
        this.showBulkTopicActionsModal(id1, "reset_bump_dates", {
          description: i18n(`topic_bulk_actions.reset_bump_dates.description`)
        });
        break;
      case "defer":
        this.showBulkTopicActionsModal(id1, "defer", {
          description: i18n(`topic_bulk_actions.defer.description`)
        });
        break;
      default:
        if (_customOnSelection[id1]) {
          this.showBulkTopicActionsModal(id1, _customOnSelection[id1].label, {
            custom: true,
            setComponent: _customOnSelection[id1].setComponent
          });
        }
    }
    this.dMenu.close();
  }
  static #_5 = (() => dt7948.n(this.prototype, "onSelect", [action]))();
  onRegisterApi(api1) {
    this.dMenu = api1;
  }
  static #_6 = (() => dt7948.n(this.prototype, "onRegisterApi", [action]))();
  static #_7 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <DMenu
        @modalForMobile={{true}}
        @autofocus={{true}}
        @identifier="bulk-select-topics-dropdown"
        @onRegisterApi={{this.onRegisterApi}}
      >
        <:trigger>
          <span class="d-button-label">
            {{i18n "select_kit.components.bulk_select_topics_dropdown.title"}}
          </span>
          {{icon "angle-down"}}
        </:trigger>
  
        <:content>
          <DropdownMenu as |dropdown|>
            {{#each this.buttons as |button|}}
              <dropdown.item>
                <DButton
                  @translatedLabel={{button.name}}
                  @icon={{button.icon}}
                  class={{concatClass "btn-transparent" button.id button.class}}
                  @action={{fn this.onSelect button.id}}
                />
              </dropdown.item>
            {{/each}}
          </DropdownMenu>
        </:content>
      </DMenu>
    
  */
  {
    "id": "y5cIm15k",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@modalForMobile\",\"@autofocus\",\"@identifier\",\"@onRegisterApi\"],[true,true,\"bulk-select-topics-dropdown\",[30,0,[\"onRegisterApi\"]]]],[[\"trigger\",\"content\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"select_kit.components.bulk_select_topics_dropdown.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[1,[28,[32,2],[\"angle-down\"],null]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"buttons\"]]],null]],null],null,[[[1,\"            \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[8,[32,4],[[16,0,[28,[32,5],[\"btn-transparent\",[30,2,[\"id\"]],[30,2,[\"class\"]]],null]]],[[\"@translatedLabel\",\"@icon\",\"@action\"],[[30,2,[\"name\"]],[30,2,[\"icon\"]],[28,[32,6],[[30,0,[\"onSelect\"]],[30,2,[\"id\"]]],null]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"        \"]],[1]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"dropdown\",\"button\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/bulk-select-topics-dropdown.js",
    "scope": () => [DMenu, i18n, icon, DropdownMenu, DButton, concatClass, fn],
    "isStrictMode": true
  }), this))();
}