import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class DBreadcrumbsItem extends Component {
  static #_ = (() => dt7948.g(this.prototype, "breadcrumbsService", [service]))();
  #breadcrumbsService = (() => (dt7948.i(this, "breadcrumbsService"), void 0))();
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{#each this.breadcrumbsService.containers as |container|}}
        {{#in-element container.element insertBefore=null}}
          <li class={{container.itemClass}} ...attributes>
            {{yield container.linkClass}}
          </li>
        {{/in-element}}
      {{/each}}
    
  */
  {
    "id": "GoG0xqvd",
    "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"breadcrumbsService\",\"containers\"]]],null]],null],null,[[[40,[[[1,\"        \"],[11,\"li\"],[16,0,[30,1,[\"itemClass\"]]],[17,2],[12],[1,\"\\n          \"],[18,3,[[30,1,[\"linkClass\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[31,3],[[30,1,[\"element\"]]],null],null]],[1]],null],[1,\"  \"]],[\"container\",\"&attrs\",\"&default\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-item.js",
    "isStrictMode": true
  }), this))();
}