import { tracked } from "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import Controller, { inject as controller } from "@ember/controller";
import { action } from "@ember/object";
import { alias, and, equal, readOnly } from "@ember/object/computed";
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import I18n from "discourse-i18n";
import esc from "../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
const customUserNavMessagesDropdownRows = [];
export function registerCustomUserNavMessagesDropdownRow(routeName, name, icon) {
  customUserNavMessagesDropdownRows.push({
    routeName,
    name,
    icon
  });
}
export function resetCustomUserNavMessagesDropdownRows() {
  customUserNavMessagesDropdownRows.length = 0;
}
export default class extends Controller {
  static #_ = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "user", [controller]))();
  #user = (() => (dt7948.i(this, "user"), void 0))();
  static #_3 = (() => dt7948.g(this.prototype, "userTopicsList", [controller]))();
  #userTopicsList = (() => (dt7948.i(this, "userTopicsList"), void 0))();
  static #_4 = (() => dt7948.g(this.prototype, "group", [tracked]))();
  #group = (() => (dt7948.i(this, "group"), void 0))();
  static #_5 = (() => dt7948.g(this.prototype, "tagId", [tracked]))();
  #tagId = (() => (dt7948.i(this, "tagId"), void 0))();
  static #_6 = (() => dt7948.g(this.prototype, "groupFilter", [alias("group.name")]))();
  #groupFilter = (() => (dt7948.i(this, "groupFilter"), void 0))();
  static #_7 = (() => dt7948.g(this.prototype, "showNewPM", [and("user.viewingSelf", "currentUser.can_send_private_messages")]))();
  #showNewPM = (() => (dt7948.i(this, "showNewPM"), void 0))();
  static #_8 = (() => dt7948.g(this.prototype, "isGroup", [equal("currentParentRouteName", "userPrivateMessages.group")]))();
  #isGroup = (() => (dt7948.i(this, "isGroup"), void 0))();
  static #_9 = (() => dt7948.g(this.prototype, "viewingSelf", [readOnly("user.viewingSelf")]))();
  #viewingSelf = (() => (dt7948.i(this, "viewingSelf"), void 0))();
  static #_10 = (() => dt7948.g(this.prototype, "currentParentRouteName", [readOnly("router.currentRoute.parent.name")]))();
  #currentParentRouteName = (() => (dt7948.i(this, "currentParentRouteName"), void 0))();
  static #_11 = (() => dt7948.g(this.prototype, "pmTaggingEnabled", [readOnly("site.can_tag_pms")]))();
  #pmTaggingEnabled = (() => (dt7948.i(this, "pmTaggingEnabled"), void 0))();
  get bulkSelectHelper() {
    this.userTopicsList.bulkSelectHelper;
  }
  get messagesDropdownValue() {
    let value;
    const currentURL = this.router.currentURL.toLowerCase();
    for (let i = this.messagesDropdownContent.length - 1; i >= 0; i--) {
      const row = this.messagesDropdownContent[i];
      if (currentURL.includes(row.id.toLowerCase().replace(this.router.rootURL, "/"))) {
        value = row.id;
        break;
      }
    }
    return value;
  }
  get messagesDropdownContent() {
    const usernameLower = this.model.username_lower;
    const content = [{
      id: this.router.urlFor("userPrivateMessages.user", usernameLower),
      name: I18n.t("user.messages.inbox")
    }];
    this.model.groupsWithMessages.forEach(group => {
      content.push({
        id: this.router.urlFor("userPrivateMessages.group", usernameLower, group.name),
        name: group.name,
        icon: "inbox"
      });
    });
    if (this.pmTaggingEnabled) {
      content.push({
        id: this.router.urlFor("userPrivateMessages.tags", usernameLower),
        name: I18n.t("user.messages.tags"),
        icon: "tags"
      });
    }
    customUserNavMessagesDropdownRows.forEach(row => {
      content.push({
        id: this.router.urlFor(row.routeName, usernameLower),
        name: row.name,
        icon: row.icon
      });
    });
    return content;
  }
  static #_12 = (() => dt7948.n(this.prototype, "messagesDropdownContent", [cached]))();
  onMessagesDropdownChange(item) {
    return DiscourseURL.routeTo(item);
  }
  static #_13 = (() => dt7948.n(this.prototype, "onMessagesDropdownChange", [action]))();
}